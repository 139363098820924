<template>
  <div class="siglesources" id="select-siglesource">
    <Select2 @select="select($event)" :settings="selectConfig" ref="select2" tabindex="0" />
  </div>
</template>

<script>
  import Select2 from 'v-select2-component'
  import config from '@/config.js'

  export default {
    name: 'SigleSourceSelect',
    props: ['exclude', 'siglesource', 'allowClear'],
    components: {
      Select2
    },
    mounted() {
      if (this.siglesource){
        this.$refs.select2.select2.append(
          `<option selected value="${this.siglesource.code}">${this.siglesource.code}</option>`
        ).trigger('change')
      }
    },
    computed: {
      selectConfig(){
        return {
          placeholder: "Select a sigle source",
          allowClear: this.allowClear == false ? false : true,
          width: '100%',
          dropdownParent: "#select-siglesource",
          ajax: {
            delay: 250,
            url: config.apiUrl + '/siglesources/autocomplete',
            headers: config.apiHeaders,
            data: (params) => {
              return {
                query: params.term
              }
            },
            processResults: (data) => {
              return {
                results: data.map(siglesource => {
                  return {
                    id: siglesource.code,
                    text: siglesource.code,
                    siglesource: siglesource
                  }
                })
              }
            }
          }
        }
      }
    },
    methods: {
      select(event){
        this.$emit('select', event.siglesource)
      }
    }
  }
</script>
