<template>
  <div class="siglesources">
    <b-container fluid="xxl">
      <b-row>
        <b-col>
          <SigleSourceSelect
            @select="select"
            :siglesource="sigleSourceRel? sigleSourceRel.siglesource : null"
            :allowClear="true"
            class="mb-3"
          />

          <b-form-group
            id="alternative-sigle"
            label="Alternative sigle:"
            label-for="alternative-sigle"
          >
            <b-form-input
              id="alternative-sigle"
              v-model="alternativeSigle"
              placeholder="Alternative sigle"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import SigleSourceSelect from "@/components/Bibliography/SigleSourceSelect.vue";
import HelpStore from '@/store/helpstore.js'
import Utils from '@/utils.js'

export default {
  name: "SigleSourceRelAutocomplete",
  props: ["exclude", "sigleSourceRel"],
  data() {
    return {
      query: "",
      timer: null,
      siglesourceObj: this.sigleSourceRel,
      alternativeSigle: this.sigleSourceRel ? this.sigleSourceRel.alternativeSigle : '',
      index: this.sigleSourceRel ? this.sigleSourceRel._index : null,
    };
  },
  components: {
    SigleSourceSelect,
  },
  methods: {
    select(siglesource) {
      if(!this.siglesourceObj) {
        this.siglesourceObj = {
          id: Utils.uuidv4(),
          siglesourceCode: siglesource.code,
          siglesource: siglesource ? siglesource : null,
          alternativeSigle: this.alternativeSigle
        }
      }
      else {
        this.siglesourceObj['siglesourceCode'] = siglesource.code;
        this.siglesourceObj['siglesource'] = siglesource;
      }
    },
  },
  updated() {
    console.log("update", this.sigleSourceRel)
    if(!this.siglesourceObj) {
      this.siglesourceObj = {
        id: Utils.uuidv4(),
        alternativeSigle: this.alternativeSigle
      }
    }
    this.siglesourceObj['alternativeSigle'] = this.alternativeSigle;
    this.siglesourceObj['_index'] = this.index;
    HelpStore.item = this.siglesourceObj
  },
};
</script>
